//
//Fullscreen header scss
//
.fullscreen-wrapper {
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  z-index: 99999;
  transition: all .6s ease-out;
  visibility: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: visibility 0s linear 1.5s, transform .5s cubic-bezier(.215, .61, .355, 1) 1s;
    transform: translateY(-100%);
    visibility: hidden;
    background-color: shade-color($primary, 60%);
  }

  .fullscreen-inner {
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    width: calc(100vw + 17px);
    position: relative;
  }
}

.fullscreen-close {
  z-index: 4;
  position: fixed;
  right: .75rem;
  top: .75rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  background-color: shade-color($primary, 0%);
  transition: opacity 0s linear .3s, transform .2s cubic-bezier(.215, .61, .355, 1) .3ms;
  opacity: 0;
  transform: translateX(-1rem);
  color: $white;
}

.fullscreen-item {
  transition: opacity .5s linear 0s, transform .5s cubic-bezier(.215, .61, .355, 1) 0s;
  opacity: 0;
  transform: translateY(3.5rem);
}

//Fullscreen gsap image link
.fullscreen-inner {
  .js-hover-img {
    transition: none;

    .js-hover-img-item {
      transition: opacity .2s, transform .4s cubic-bezier(.215, .61, .355, 1) .1s;
      transform: translate3d(0, 30px, 0);
      opacity: 0;
      .js-hover-img-link {
        transition: none;

        &:not(:hover):not(.active) {
          mix-blend-mode: screen;
        }
      }
    }
  }
}

.fullscreen-open {
  overflow: hidden;
  height: 100%;
  width: 100vw;
  .fullscreen-inner {
    .js-hover-img {
      .js-hover-img-item {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s linear .8s, transform .5s cubic-bezier(.215, .61, .355, 1) .8s;

        @for $i from 1 through 21 {
          &:nth-child(#{$i}) {
            transition-delay: $i * 300ms;
          }
        }
      }
    }
  }

  .fullscreen-item {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s linear .8s, transform .5s cubic-bezier(.215, .61, .355, 1) .8s;

    @for $i from 1 through 21 {
      &:nth-child(#{$i}) {
        transition-delay: $i * 300ms;
      }
    }
  }

  .fullscreen-close {
    opacity: 1;
    transform: none;
    transition: opacity 0s linear .4s, transform .4s cubic-bezier(.55, .055, .675, .19) .4s;
  }

  .fullscreen-wrapper {
    visibility: visible;

    &::before {
      visibility: visible;
      transform: translateY(0%);
      transition: visibility 0s linear .1s, transform .4s cubic-bezier(.215, .61, .355, 1) .1s;
    }
  }
}

@include media-breakpoint-up(lg){
  .fullscreen-wrapper .fullscreen-inner{
    display: flex;
    align-items: center;
  }
}