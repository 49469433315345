// Utilities

$utilities: map-merge($utilities,
    ("rounded": (class:rounded,
            property: border-radius,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                blob:$border-radius-blob,
                circle:50%),
            responsive: true,
        ),
        "rounded-top": (property: border-top-left-radius border-top-right-radius,
            class: rounded-top,
            responsive: true,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-end": (property: border-top-right-radius border-bottom-right-radius,
            class: rounded-end,
            responsive: true,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-start": (property: border-top-left-radius border-bottom-left-radius,
            class: rounded-start,
            responsive: true,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-top-start": (property: border-top-left-radius,
            class: rounded-top-start,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-top-end": (property: border-top-right-radius,
            class: rounded-top-end,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-bottom": (property: border-bottom-left-radius border-bottom-right-radius,
            class: rounded-bottom,
            responsive: true,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-bottom-start": (property: border-bottom-left-radius,
            class: rounded-bottom-start,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        "rounded-bottom-end": (property: border-bottom-right-radius,
            class: rounded-bottom-end,
            values: (0:0,
                null:$border-radius,
                1:$border-radius-sm,
                2:$border-radius,
                3:$border-radius-lg,
                4:$border-radius-xl,
                pill:$border-radius-pill,
                block:$border-radius-block,
                circle:50%)),
        // scss-docs-start utils-shadow
        "shadow": (property: box-shadow,
            class: shadow,
            values: (null: $box-shadow,
                sm: $box-shadow-sm,
                lg: $box-shadow-lg,
                xl: $box-shadow-xl,
                3d: $box-shadow-3d,
                none: none,
            )),
        // scss-docs-start utils-shadow
        "rotate": (property: transform,
            class: rotate,
            values: (1: rotate(1deg),
                2: rotate(2deg),
                3: rotate(3deg),
                4: rotate(4deg),
                5: rotate(5deg),
                45: rotate(45deg),
                n45: rotate(-45deg),
                90: rotate(90deg),
                -90: rotate(-90deg),
                180: rotate(180deg),
                -180: rotate(-180deg),
                n1: rotate(-1deg),
                n2: rotate(-2deg),
                n3: rotate(-3deg),
                n4: rotate(-4deg),
                n5: rotate(-5deg),
                none: none,
            )),
        "font-weight": (property: font-weight,
            class: fw,
            values: (lighter: $font-weight-lighter,
                light: $font-weight-light,
                normal: $font-weight-normal,
                semibold: $font-weight-semibold,
                bold: $font-weight-bold,
                bolder: $font-weight-bolder)),
        "flip": (class:flip,
            property: transform,
            values: (y:rotateZ(180deg),
                x:rotateY(180deg))),
        //Viewport width and height
        "viewport-height": (property: height,
            class: vh,
            values: (0: 0,
                25: 25vh,
                50: 50vh,
                75: 75vh,
                100: 100vh)),
        "min-viewport-height": (property: min-height,
            class: min-vh,
            values: (0: 0,
                25: 25vh,
                50: 50vh,
                75: 75vh,
                100: 100vh)),
        "z-index": (class:z-index,
            property: z-index,
            values: (n1:-1,
                n2:-2,
                0:0,
                1:1,
                2:2,
                3:3,
                4:4,
                5:5,
                fixed:$zindex-fixed)),
        "width": (responsive: true,
            class:w,
            property: width,
            values: (0:0%,
                10:10%,
                20:20%,
                25:25%,
                30:30%,
                40:40%,
                50:50%,
                60:60%,
                70:70%,
                75:75%,
                80:80%,
                90:90%,
                100:100%, auto:auto,
            )),
        "height": (responsive: true,
            class:h,
            property: height,
            values: (0:0%,
                10:10%,
                20:20%,
                25:25%,
                30:30%,
                40:40%,
                50:50%,
                60:60%,
                70:70%,
                75:75%,
                80:80%,
                90:90%,
                100:100%,
                auto:auto,
            )),
        "border": map-merge(map-get($utilities, "border"),
            (responsive: true),
        ),
        "border-top": map-merge(map-get($utilities, "border-top"),
            (responsive: true),
        ),
        "border-start": map-merge(map-get($utilities, "border-start"),
            (responsive: true),
        ),
        "border-end": map-merge(map-get($utilities, "border-end"),
            (responsive: true),
        ),

        "position": map-merge(map-get($utilities, "position"),
            (responsive: true),
        ),
        "j-center": (property: align-items,
            class: flex,
            values: (center: center,
            )),
        "align-center": (property: justify-content,
            class: flex,
            values: (center: center,
            )),
        //Background-position
        "backgroundPosition": (property: background-position,
            class: bg,
            values: (center:center center,
                top-left:top left,
                top-right:top right,
                bottom-left:bottom left,
                bottom-right:bottom right,
            )),
        //Background-size
        "backgroundSize": (property: background-size,
            class: bg,
            values: (cover:cover,
                contain:contain,
                100:100%)),
        //Background-size
        "backgroundRepeat": (property: background-repeat,
            class: bg,
            values: (repeat:repeat,
                no-repeat:no-repeat,
                repeat-x:repeat-x,
                repeat-y:repeat-y,
            )),
        // Sizing utilities
        // scss-docs-start utils-sizing
        "widthPixel": (property: width,
            class: width,
            values: (1x: 1rem,
                2x: 1.5rem,
                3x: 2rem,
                4x: 2.5rem,
                5x: 3rem,
                6x: 3.5rem,
                7x: 4rem,
                8x: 5rem,
                9x: 5.5rem,
                10x: 6rem,
                11x: 7rem,
                12x: 8rem,
                13x:9rem,
                14x:9.5rem,
                15x: 10rem,
                16x: 11rem,
                17x: 12rem,
                18x: 13rem,
                19x: 14rem,
                20x: 15rem,
                auto: auto)),
        "heightPixel": (property: height,
            class: height,
            values: (1x: 1rem,
                2x: 1.5rem,
                3x: 2rem,
                4x: 2.5rem,
                5x: 3rem,
                6x: 3.5rem,
                7x: 4rem,
                8x: 5rem,
                9x: 5.5rem,
                10x: 6rem,
                11x: 7rem,
                12x: 8rem,
                13x:9rem,
                14x:9.5rem,
                15x: 10rem,
                16x: 11rem,
                17x: 12rem,
                18x: 13rem,
                19x: 14rem,
                20x: 15rem,
                auto: auto)),
    ));




//Additional utilities
.flex-center {
    display: inline-flex !important;
}

//Border-dashed
.border-dashed {
    border-style: dashed !important;
    border-width: inherit !important;
}

//Hover box-shadows
[class~="hover-shadow-"],
[class*="hover-shadow-"],
.hover-shadow {
    transition: all .25s ease-in-out;
}

.hover-shadow-sm {
    &:hover {
        box-shadow: $box-shadow-sm !important;
    }
}

.hover-shadow {
    &:hover {
        box-shadow: $box-shadow !important;
    }
}

.hover-shadow-lg {
    &:hover {
        box-shadow: $box-shadow-lg !important;
    }
}

.hover-shadow-xl {
    &:hover {
        box-shadow: $box-shadow-xl !important;
    }
}

.hover-shadow-3d {
    &:hover {
        box-shadow: $box-shadow-3d !important;
    }
}


//grid separator
.grid-separator {
    overflow: hidden;

    >* {
        box-shadow: -1px -1px 0px 0px rgba($border-color, 1);
    }

    >[class*="col-"],
    [class^="col-"] {
        padding: 2rem 1rem;
    }
}

.text-white {
    .grid-separator {
        >* {
            box-shadow: -1px -1px 0 0 rgba($white, .125);
        }
    }
}